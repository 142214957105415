import React, { useState } from "react";
import styles from "./ErrorBottomDrawer.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CommonButton from "../CommonButton/CommonButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      "@media (min-width: 500px)": {
        width: "50%",
        margin: "auto",
      },
    },
  },
});

const ErrorBottomDrawer = ({
  errorMessage = null,
  onClickHandler,
  info = false,
}) => {
  const classes = useStyles();
  const [state] = useState({
    bottom: true,
  });

  const list = (anchor) => (
    <Box
      className={styles["drawer"]}
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
    >
      <div className="flex items-center gap-2 ">
        {<ErrorOutlineIcon sx={{ color: info ? "blue" : "red" }} />}
        <ListItemText primary={info ? "Info" : "Error"} />
      </div>
      <div className="p-[5px]"></div>
      <p>{errorMessage}</p>
      <div className="p-[5px]"></div>
      <div className="p-[5px]"></div>
      <div className="flex  text-center justify-between gap-10 md:w-[235px] md:m-auto">
        <CommonButton
          id="error-info-ok-button"
          label={"OK"}
          center
          filled
          onClickHandler={() => onClickHandler(errorMessage)}
        />
      </div>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor, index) => (
        <React.Fragment key={Math.random() + index}>
          <Drawer anchor={anchor} open={state[anchor]} classes={classes}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ErrorBottomDrawer;
